import ProfilePremiumSection from "../premium/profilePremium"
import ChannelSettings from "./channelSettings"
import WhitelistTable from "./profileWhitelistTable"
import { useAppSelector } from "../../../../state/hooks";

export default function ProfileManagementSection() {
    const profileSate = useAppSelector(state => state.profileCollection)
    
    return (
        <>
            <div className="md:flex max-w-8xl py-2 justify-center md:justify-start items-center">
                <h1 className="text-2xl text-center font-secondary md:mr-4
                text-animate bg-gradient-to-r from-white via-purple-300 to-teal-300 bg-clip-text text-transparent font-black">2. Manage Channels</h1>
                <ProfilePremiumSection/>
            </div>
            <div className="md:flex max-w-8xl md:pt-2 justify-center lg:justify-start items-start">
                <div className="flex w-full md:w-1/2 h-96">
                    <div className="w-full h-full overflow-y-auto border-2 border-white rounded-lg p-2">
                        <WhitelistTable/>
                    </div>
                </div>
                <div className="flex w-full md:w-1/2">
                    <div className="mt-2 md:mt-0 ml-0 md:ml-2 w-full">
                        <div className="w-full h-64 border-2 border-white rounded-lg p-4">
                            <ChannelSettings/>
                        </div>
                        <div className="h-24 p-2">
                            <div className="flex items-center my-2">
                                <h1 className="text-white font-third text-md my-1 mr-2">Registered Channels: {profileSate.registeredTgChats.length}</h1>
                            </div>
                            <div className="flex my-2">
                                <h1 className="text-white font-third text-md my-1 mr-2">Total generations left: {profileSate.userTierInfo.generationsLeft}</h1>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}



