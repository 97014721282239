import axios from "axios";
import { Network } from "../../../models";
import { AccountLink, ACCOUNT_TYPE } from "../../../models/modal";
import { updateDiscordLink, updateTelegramLink } from "../../../state/reducers/user/userProfileReducer";
import HandleAlert, { accountLinkSuccess } from "../../../utils/handleAlert";

const TELEGRAM_LINK_ROUOTE = "https://webhook.lucidai.art/updateTelegramLink"
const DSICORD_LINK_ROUOTE = "https://webhook.lucidai.art/updateDiscordLink"


export async function linkTelegramAccount(account: AccountLink, telegramData: any, network: Network, dispatch: any): Promise<boolean> {
    if (network.jwtToken === "" || network.selectedAccount === "") {
        return false;
    }
    
    if (account.username !== "" && !telegramData) {
        return false;
    }
    
    let body = {
        publicAddress: network.selectedAccount,
        telegramUsername: account.username,
        telegramHashData: telegramData
    }

    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let response = undefined;
    try {
        response = await axios.post(TELEGRAM_LINK_ROUOTE, body, { headers: headers });
        if (response.data && response.data.username !== undefined) {
            dispatch(updateTelegramLink(response.data.username))
            successAlert(account, response.data.username, dispatch);
            return true;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
    return false;
}

export async function linkDiscordAccount(account: AccountLink, discordAuthCode: string, removeLink: boolean, network: Network, dispatch: any): Promise<boolean> {
    if (network.jwtToken === "" || network.selectedAccount === "") {
        return false;
    }
    
    if (!removeLink && !discordAuthCode) {
        return false;
    }
    
    let body = {
        publicAddress: network.selectedAccount,
        authCode: discordAuthCode,
        removingLink: removeLink
    }

    const headers = {
        'Authorization': `Bearer ${network.jwtToken}`
    };

    let response = undefined;
    try {
        response = await axios.post(DSICORD_LINK_ROUOTE, body, { headers: headers });
        if (response.data && response.data.username !== undefined) {
            dispatch(updateDiscordLink(response.data.username))
            successAlert(account, response.data.username, dispatch);
            return true;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
    return false;
}


function successAlert(account: AccountLink, username: string, dispatch: any) {
    let alertBody = (account.type === ACCOUNT_TYPE.DISCORD ? "Discord: @" : "Telegram: @") + username;
    let updateText = username !== "" ? "Linked " + alertBody : "Successfully Removed Link"
    HandleAlert(accountLinkSuccess(updateText), dispatch);
}


export async function unlinkAccount(account: AccountLink, network: Network, dispatch: any): Promise<boolean> {
    if (network.jwtToken === "") {
        return false;
    }
    let alertBody = (account.type === ACCOUNT_TYPE.DISCORD ? "Discord: @" : "Telegram: @") + account.username;
    HandleAlert(accountLinkSuccess("Removed " + alertBody), dispatch);
    return true;
}